<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        兀型運動科技股份有限公司
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">不同運動之肌力表現常模雷達圖</p>
        <p class="image_desc">圖片提供：兀型運動科技</p>
      </div>
      <div class="image-container">
        <img :src="image2" class="image" alt="">
        <p class="image_desc">相同運動不同角色之常模</p>
        <p class="image_desc">圖片提供：兀型運動科技</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          隨著健康運動產業的日益興盛，運動員的訓練需求變得更加迫切，因此建立訓練依據：透過大數據庫的建立了解數據優劣，同時降低運動成本，成為民眾渴望解決的一個課題，與此同時，希望可追蹤運動員長期表現、了解運動員的成長歷程，使教練對運動員的過去能更加認識以利後續訓練變得迫在眉睫，在競技賽場上，不同的項目對應到肌力特質比例不同，而這些特質往往是決勝的關鍵之一，故兀型運動科技股份有限公司透過測力板、速度感測閘門、田野測試及專項技術相關器材如：Rapsodo pitching 建立通用之肌力表現雷達圖。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據實證活動</div>
        <div class="content-desc">
          於實證期間，本計畫輔導廠商，開發不同運動、相同運動中不同角色、相同運動不同層級之之肌力常模雷達圖共12種，而透過與個人之數據與常模之比較可發展診斷、訓練等數據應用，因為實證之易佈署性，亦可與其他專項運動檢測搭配(如另一團隊之智慧牛棚)進行公益巡迴檢測。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          利用離心發力率/向心發力率/反應肌力/最大肌力/峰值功率等數據，建立以數據為基礎的訓練依據，亦可同時透過兀型運動科技股份有限公司所建立之GoTop運動表現數據整合平台創建運動員訓練履歷，強化運動員曝光。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公數據應用效益/展望</div>
        <div class="content-desc">
          實證過程受益範圍將涵蓋體育從業人員、運動選手、數位產業、職業球團、社會大眾，更有望解決民眾現有需求與追蹤運動員長期表現用於診斷與訓練。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11211',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-11-1.png'),
      image2: require('@/assets/images/sample/112-11-2.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
